<template>
  <div class="app-container">
    <Role :currentRow="currentRow" />
  </div>
</template>

<script>
import Role from "./role.vue";
export default {
  components: {
    Role,
  },
  created() {
    const { userAccount, userId, tenantId } = this.$route.query;
    this.currentRow = { userAccount, userId, tenantId };
    this.$eventBus.$emit("setDetailAlias", userAccount);
  },
  mounted() {},
};
</script>

<style>
</style>